export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const TOKENS = 'TOKENS';
export const SELECT_TOKEN = 'SELECT_TOKEN';
export const TRIGGER_METADATA_UPDATE = 'TRIGGER_METADATA_UPDATE';

export const RACE_SET_SCORE = 'RACE_SET_SCORE';
export const RACE_SET_ACTIVE_RACE = 'RACE_SET_ACTIVE_RACE';
export const RACE_SET_RACERS_LOADED = 'RACE_SET_RACERS_LOADED';

export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const TITLES_TITLE = 'TITLES_TITLE';
export const SOUND_PLAY = 'SOUND_PLAY';
export const MAP_ZOOM = 'MAP_ZOOM';
export const MAP_TRIGGER_ZOOM = 'MAP_TRIGGER_ZOOM';
export const PLAYER_COMPONENT = 'PLAYER_COMPONENT';
export const SEASON_SET = 'SEASON_SET';

export const SOCKETS_DATA = 'SOCKETS_DATA';