import { ethers } from 'ethers';
import Request from './Api';
import APIMetaMask from './MetaMask';

// get info
const get = async (props) => {

    let user = await Request({
        url: "/players"+(props?.id?"/"+props.id:""),
    });

    if (user.data){
        /* // get achievements
        user.data.achievements = null;
        try{
            const achv=await achievements({id:user.data.id});
            if (achv.data.length) user.data.achievements = achv.data;
        } catch(e){}
        */

        // get og pass
        if (ethers.utils.isAddress(props.id) && user.data){
            const is_OG=await passes.og({wallet:props.id});
            user.data.is_OG = +is_OG>0;
        }
    }

    return user;
}

// add new
const add = async (props) => {
    return (
        Request({
            url: "/players",
            data: props,
            method: "POST"
        })
    );
}

// update user
const update = async (props) => {
    return (
        Request({
            url: "/players/"+props.id,
            data: props,
            method: "PUT"
        })
    );
}

// passes
const passes = {
    og: async props =>{
        // get og pass   
        let og = await APIMetaMask.getCoinBalance({
            wallet:props.wallet,
            contractAddress:process.env.REACT_APP_OG_CONTRACT_ADDRESS,
            network:"homestead", //"rinkeby",
            erc1155Id:1,
            format:"count"
        });
        if (og.data) return og.data.balance;
        return 0;
    },
}

// leaderboard
const leaderboard = {
    solo: async props =>{
        let res=await Request({
            url: "/players/leaderboard",
            data: props,
        });
        if (res.data) res.data = [...res.data].slice(0,8);
        return res;
    },
    team: async props =>{
        let res=await Request({
            url: "/players/leaderboard/team",
            data: {...props,team:1},
        });
        if (res.data) res.data = [...res.data].slice(0,8);
        return res;
    }
}

// achievements
const achievements = async (props) => {
    return (
        Request({
            url: "/players/achievements/"+props.id,
            data: props,
        })
    );
}

// inventory
const inventory = {
    get: props =>{
        if (props.player_id){
            return (
                Request({
                    url: "/players/items/"+props.player_id+"/all",
                    //data: props,
                })
            );
        } else {
            return (
                Request({
                    url: "players/items/"+props.id,
                    //data: props,
                })
            );
        }

    },
    equip: async props =>{
        return Request({
            url: "/players/tokens/items",
            method: "POST",
            data: props,
        });
    },
    unequip: async props =>{
        return Request({
            url: "/players/tokens/items/"+props.id,
            method: "DELETE",
            data: props,
        });
    },
    nft: async props =>{
        return Request({
            url: "/players/tokens/nft",
            method: "POST",
            data: props,
        });
    },

       
}

// tokens
const tokens = {
    metadata: async props =>{
        const res=await Request({
            url: "/players/tokens/"+props.id+"/metadata",
            method: "GET",
            data: props,
        });

        if (res.data){
            const res2=await Request({
                url: res.data,
                method: "GET",
            });
            return res2;
        } return null;
    },
    updateMetaData : async props => {
        return Request({
            url: "/players/tokens/update/"+props.id,
            method: "POST",
            data: props,
        });

        /*
        if (signed_url.data){
            const metadata=await APIMetaMask.createDummyMetadata({token_id:props.id});
            const file = new File([JSON.stringify(metadata)], `${props.id}.json`, {
                type: "application/json",
            });
                
            const res=await Request({
                url: signed_url.data,
                method: "PUT",
                data: file,
            });
            return {data:"Success",res,error:null};
        } else return {data:null,error:"No signed url."};
        */
    },
    equipment: props =>{
        return (
            Request({
                url: "/players/tokens/items/"+props.token_id+"/all",
                //data: props,
            })
        );
    },
    get: props =>{
        if (props.player_id){
            return (
                Request({
                    url: "/players/tokens/"+props.player_id+"/all",
                    //data: props,
                })
            );
        } else {
            return (
                Request({
                    url: "/players/token/"+props.id,
                    //data: props,
                })
            );
        }

    },
    add: props =>{
        return (
            Request({
                url: "/players/tokens",
                data: props,
                method: "POST"
            })
        );
    },
    update: props =>{
        return (
            Request({
                url: "/players/tokens/"+props.id,
                data: props,
                method: "PUT"
            })
        );
    },
    delete: props =>{
        return (
            Request({
                url: "/players/tokens/"+props.id,
                data: props,
                method: "DELETE"
            })
        );
    },
}

const Players = {
    get, add, update, tokens, leaderboard, passes, achievements, inventory //, delete, etc. ...
}
export default Players;